import QRCode from 'react-qr-code';

export default function Mariage() {
    return <div className={`py-[50px]`}>
        <div className={`w-[250px] h-[250px] m-auto`}>
            <div className={`mb-[20px]`}>
                Chinese
            </div>
            <QRCode value={'https://nathxnicowedding.my.canva.site/copie-de-nath1'} className={`w-[200px] h-[200px]`}/>
        </div>
        <div className={`border w-[250px] m-auto my-[20px]`}></div>
        <div className={`w-[250px] h-[250px] m-auto`}>
            <div className={`mb-[20px]`}>
                French
            </div>
            <QRCode value={'https://nathxnicowedding.my.canva.site/'} className={`w-[200px] h-[200px]`}/>
        </div>
    </div>;
}
