import {BrowserRouter, Routes, Route} from "react-router-dom";
import Homepage from "./Pages/Homepage";
import Error404NotFound from "./Pages/Error404NotFound";
import {Register} from "./Pages/Register";
import {LogIn} from "./Pages/LogIn";
import {isFeatureAllowed, isLoggedIn, isUserGroup} from "./Signals/UserAccountSignal";
import {LoggedOut} from "./Pages/LoggedOut";
import {UserSelfProfile} from "./Pages/UserSelfProfile";
import {ConfigurePassword} from "./Pages/ConfigurePassword";
import {ExtensionInstalled} from "./Pages/ExtensionInstalled";
import {ExtensionAutoLogIn} from "./Pages/ExtensionAutoLogIn";
import {WebsiteExtensionAutoLogIn} from "./Pages/WebsiteExtensionAutoLogIn";
import BackOfficeHomepage from "./Pages/BackOfficeHomepage";
import {BackOfficeAdministrators} from "./Pages/BackOfficeAdministrators";
import {BackOfficeCouponAutoApplies} from "./Pages/BackOfficeCouponAutoApplies";
import {Coupons} from "./Pages/Coupons";
import {BackOfficeCoupons} from "./Pages/BackOfficeCoupons";
import {BackOfficeCouponUpdate} from "./Pages/BackOfficeCouponUpdate";
import {CouponUpdate} from "./Pages/CouponUpdate";
import {BackOfficeCompanies} from "./Pages/BackOfficeCompanies";
import {BackOfficeCompanyUpdate} from "./Pages/BackOfficeCompanyUpdate";
import {BackOfficeCouponAutoApplyUpdate} from "./Pages/BackOfficeCouponAutoApplyUpdate";
import {Youtube} from "./Pages/Youtube";
import {YoutubeSettings} from "./Pages/YoutubeSettings";
import {BackOfficeYoutubeUpdate} from "./Pages/BackOfficeYoutubeUpdate";
import {BackOfficeNews} from "./Pages/BackOfficeNews";
import {BackOfficeNewsConfigs} from "./Pages/BackOfficeNewsConfigs";
import {BackOfficeNewsConfigUpdate} from "./Pages/BackOfficeNewsConfigUpdate";
import {BackOfficeNewsConfigCreate} from "./Pages/BackOfficeNewsConfigCreate";
import {News} from "./Pages/News";
import {Activities} from "./Pages/Activities";
import {Board} from "./Pages/Board";
import Playlist from "./Pages/Playlist";
import {CreateNote} from "./Pages/CreateNote";
import {UpdateNote} from "./Pages/UpdateNote";
import YoutubeRemote from "./Pages/YoutubeRemote";
import { BackOfficeWeatherApiKeysUpdate } from './Pages/BackOfficeWeatherApiKeysUpdate';
import { CouponCreate } from './Pages/CouponCreate';
import { Weather } from './Pages/Weather';
import { BackOfficeUsers } from './Pages/BackOfficeUsers';
import { BackOfficeAutomationLogs } from './Pages/BackOfficeAutomationLogs';
import { Links } from './Pages/Links';
import { QRCodeAutoLogIn } from './Pages/QRCodeAutoLogIn';
import { Karaoke } from './Pages/Karaoke';
import { ForgotPassword } from './Pages/ForgotPassword';
import { UserProfile } from './Pages/UserProfile';
import { AboutUs } from './Pages/AboutUs';
import { Roadmap } from './Pages/Roadmap';
import { Team } from './Pages/Team';
import { BackOfficeSpaceshareRates } from './Pages/BackOfficeSpaceshareRates';
import { BackOfficeBugReports } from './Pages/BackOfficeBugReports';
import { BackOfficeContactAdmins } from './Pages/BackOfficeContactAdmins';
import Mariage from './Pages/Mariage';

export default function Router() {
    return <BrowserRouter>
        <Routes>
            <Route path={'/'} element={<Homepage/>}/>

            <Route path={'/extensions/installed'} element={<ExtensionInstalled/>}/>
            <Route path={'/extensions/install-auto-log-in'} element={<ExtensionAutoLogIn/>}/>
            <Route path={'/extensions/auto-log-in'} element={<ExtensionAutoLogIn/>}/>
            <Route path={'/extensions/website-log-in'} element={<WebsiteExtensionAutoLogIn/>}/>

            <Route path={'/coupons'} element={<Coupons/>}/>
            <Route path={'/news'} element={<News/>}/>
            <Route path={'/youtube'} element={<Youtube/>}/>
            <Route path={'/activities'} element={<Activities/>}/>
            <Route path={'/youtube-remote/:id'} element={<YoutubeRemote/>}/>
            <Route path={'/weather'} element={<Weather/>}/>
            <Route path={'/users/:userId/links'} element={<Links/>}/>
            <Route path={'/karaoke'} element={<Karaoke/>}/>
            <Route path={'/users/:userId'} element={<UserProfile/>}/>
            <Route path={'/about-us'} element={<AboutUs/>}/>
            <Route path={'/roadmap'} element={<Roadmap/>}/>
            <Route path={'/team'} element={<Team/>}/>
            <Route path={'/mariage'} element={<Mariage/>}/>

            {!isLoggedIn() && <Route path={'/register'} element={<Register/>}/>}
            {!isLoggedIn() && <Route path={'/log-in'} element={<LogIn/>}/>}
            {!isLoggedIn() && <Route path={'/logged-out'} element={<LoggedOut/>}/>}
            {!isLoggedIn() && <Route path={'/configure-password'} element={<ConfigurePassword/>}/>}
            {!isLoggedIn() && <Route path={'/forgot-password'} element={<ForgotPassword/>}/>}
            {isLoggedIn() && <Route path={'/users/self'} element={<UserSelfProfile/>}/>}
            {isLoggedIn() && <Route path={'/coupons/create'} element={<CouponCreate/>}/>}
            {isLoggedIn() && <Route path={'/coupons/:id/update'} element={<CouponUpdate/>}/>}
            {isLoggedIn() && <Route path={'/youtube/settings'} element={<YoutubeSettings/>}/>}
            {isLoggedIn() && <Route path={'/boards/:id'} element={<Board/>}/>}
            {isLoggedIn() && <Route path={'/playlists/:id'} element={<Playlist/>}/>}
            {isLoggedIn() && <Route path={'/notes/create'} element={<CreateNote/>}/>}
            {isLoggedIn() && <Route path={'/notes/:id'} element={<UpdateNote/>}/>}
            {isLoggedIn() && <Route path={'/qr-code-auto-log-in/:deviceId'} element={<QRCodeAutoLogIn/>}/>}

            {isUserGroup('admin') && <Route path={'/back-office'} element={<BackOfficeHomepage/>}/>}
            {isUserGroup('admin') && <Route path={'/back-office/automation-logs'} element={<BackOfficeAutomationLogs/>}/>}
            {isUserGroup('admin') && <Route path={'/back-office/spaceshare-rates'} element={<BackOfficeSpaceshareRates/>}/>}
            {isUserGroup('admin') && <Route path={'/back-office/bug-reports'} element={<BackOfficeBugReports/>}/>}
            {isUserGroup('admin') && <Route path={'/back-office/contact-admins'} element={<BackOfficeContactAdmins/>}/>}
            {isFeatureAllowed('back_office_users', 'rightRead') && <Route path={'/back-office/users'} element={<BackOfficeUsers/>}/>}
            {isFeatureAllowed('back_office_administrators', 'rightRead') && <Route path={'/back-office/administrators'} element={<BackOfficeAdministrators/>}/>}
            {isFeatureAllowed('back_office_coupon_auto_apply', 'rightRead') && <Route path={'/back-office/coupon-auto-applies'} element={<BackOfficeCouponAutoApplies/>}/>}
            {isFeatureAllowed('back_office_coupons', 'rightRead') && <Route path={'/back-office/coupons'} element={<BackOfficeCoupons/>}/>}
            {isFeatureAllowed('back_office_coupons', 'rightUpdate') && <Route path={'/back-office/coupons/:id/update'} element={<BackOfficeCouponUpdate/>}/>}
            {isFeatureAllowed('back_office_companies', 'rightRead') && <Route path={'/back-office/companies'} element={<BackOfficeCompanies/>}/>}
            {isFeatureAllowed('back_office_companies', 'rightUpdate') && <Route path={'/back-office/companies/:id/update'} element={<BackOfficeCompanyUpdate/>}/>}
            {isFeatureAllowed('back_office_coupon_auto_apply', 'rightUpdate') && <Route path={'/back-office/coupon-auto-applies/:id/update'} element={<BackOfficeCouponAutoApplyUpdate/>}/>}
            {isFeatureAllowed('back_office_youtube', 'rightUpdate') && <Route path={'/back-office/youtube/update'} element={<BackOfficeYoutubeUpdate/>}/>}
            {isFeatureAllowed('back_office_weather', 'rightUpdate') && <Route path={'/back-office/weathers/api-keys/update'} element={<BackOfficeWeatherApiKeysUpdate/>}/>}
            {isFeatureAllowed('back_office_news', 'rightRead') && <Route path={'/back-office/news'} element={<BackOfficeNews/>}/>}
            {isFeatureAllowed('back_office_news_configs', 'rightRead') && <Route path={'/back-office/news-configs'} element={<BackOfficeNewsConfigs/>}/>}
            {isFeatureAllowed('back_office_news_configs', 'rightUpdate') && <Route path={'/back-office/news-configs/:id/update'} element={<BackOfficeNewsConfigUpdate/>}/>}
            {isFeatureAllowed('back_office_news_configs', 'rightCreate') && <Route path={'/back-office/news-configs/create'} element={<BackOfficeNewsConfigCreate/>}/>}

            <Route path='*' element={<Error404NotFound/>} />
        </Routes>
    </BrowserRouter>;
}
